const focusNextElement = (currentElement) => {
    // Try to get focusable elements from the current form (much safer) or the whole document
    const nextElement = getNextFocusableElement(currentElement);
    if (nextElement) {
        nextElement.focus();
    }
};

const getNextFocusableElement = (currentElement) => {
    const focusable = getFocusableElements(
        currentElement.form ? currentElement.form : document
    ).filter(
        (element) =>
            element.offsetWidth > 0 ||
            element.offsetHeight > 0 ||
            element === document.activeElement
    );

    if (!focusable) {
        return;
    }
    const index = focusable.indexOf(document.activeElement);
    if (index > -1) {
        const nextElement = focusable[index + 1] || focusable[0];
        return nextElement;
    }
    return;
};
const getFocusableElements = (elem) => {
    return [
        ...elem.querySelectorAll(
            'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
        ),
    ].filter(
        (el) =>
            !el.hasAttribute('disabled') &&
            !el.getAttribute('aria-hidden') &&
            el.getAttribute('input') !== 'hidden'
    );
};

export { focusNextElement };
