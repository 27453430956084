export default () => {
    return (input, step, delimiter, thousands) => {
        let parsedInput = input.toString();
        parsedInput = parsedInput ? parsedInput.replaceAll(delimiter, '{DECIMAL}') : "";
        if (thousands.length) {
            parsedInput.replaceAll(thousands, '');
        }
        parsedInput = parsedInput.replaceAll('{DECIMAL}', '.');
        let addThousands = (input, thousands) => {
            let output = ''
            let counter = 0

            for (let i = input.length - 1; i >= 0; i--) {
                if (input[i] === thousands) continue;

                if (counter === 3) {
                    output = input[i] + thousands + output
                    counter = 0
                } else {
                    output = input[i] + output
                }
                counter++
            }

            return output;
        }

        let decimalCount = () => {
            // Convert to String
            const numStr = String(step);
            const divider = numStr.includes('.') ? '.' : numStr.includes(',') ? ',' : false;
            // String Contains Decimal
            if (divider) {
                return numStr.split(divider)[1].length;
            }
            // String Does Not Contain Decimal
            return 0;
        };

        let nothousands = parsedInput.replaceAll(thousands, '')
        let template = Array
            .from({ length: nothousands.split('.')[0].length })
            .fill('9')
            .join('')

        template = addThousands(template, thousands)

        if (parsedInput.includes('.')) {
            template += `${delimiter}`;

            for (let step = 0; step < decimalCount(); step++) {
                template += '9';
            }
        }

        return template;
    }
}