import { Alpine, Livewire } from '../../../vendor/livewire/livewire/dist/livewire.esm';

import ui from '@alpinejs/ui';
import numbermask from '../alpine/numbermask';
import autocomplete from './../alpine/autocomplete';
import charts from './../alpine/charts';
import datepicker from './../alpine/datepicker';
import dropdown from './../alpine/dropdown';
import select from './../alpine/select';
import toasts from './../alpine/toasts';
import wysiwyg from './../alpine/wysiwyg';
import initDisableBodyScroll from './disable-body-scroll';
import { focusNextElement } from './helpers';
import initStepNavigation from './step-navigation';

import flatpickr from 'flatpickr';
import FlatpickrLanguages from "flatpickr/dist/l10n";

/**
 * Initializes all UI components and starts AlpineJS
 * Has callbacks so you can run your code before
 * or after initializing components and before AlpineJS starts
 *
 * @returns {void}
 * @param {function} beforeCallback
 * @param {function} afterCallback
 */
export default function init(beforeCallback = null, afterCallback = null) {
    if (typeof beforeCallback === 'function') {
        beforeCallback();
    }

    initDisableBodyScroll();
    initStepNavigation();

    Alpine.data('autocomplete', autocomplete);
    Alpine.data('charts', charts);
    Alpine.data('datepicker', datepicker);
    Alpine.data('dropdown', dropdown);
    Alpine.data('toasts', toasts);
    Alpine.data('select', select);
    Alpine.data('wysiwyg', wysiwyg);
    Alpine.magic('focusNextElement', (el) => {
        focusNextElement(el);
    });


    Alpine.magic('numberMask', numbermask);
    Alpine.magic('flatpickr', () => {
        return flatpickr;
    })
    Alpine.magic('flatpickrLanguages', () => {
        const languages = {};
        Object.keys(FlatpickrLanguages).forEach(function (key, index) {
            languages[key] = {
                ...FlatpickrLanguages[key],
                rangeSeparator: ' - ',
            };
        });
        return languages;
    })

    Alpine.plugin(ui);

    if (typeof afterCallback === 'function') {
        afterCallback();
    }

    Alpine.magic('dialog', () => {
        return (modal, action, actionParams, content, title) => {
            const detail = {
                action,
                actionParams,
            };

            if (title) {
                detail.title = title;
            }

            if (content) {
                detail.content = content;
            }
            const event = new CustomEvent(
                `dialog-${modal}`,
                {
                    detail,
                });
            window.dispatchEvent(event);
        }
    })


    Livewire.start();
}
