export default () => {
    return {
        isOpen: false,
        focusableElements: [],

        init() {
            // Find all focusable child elements from this level
            this.focusableElements = [
                ...this.$refs.content.querySelectorAll(':scope > [role=menu] > [role=menuitem]'),
            ]
                .map(
                    (el) =>
                        el.querySelector('button') ||
                        el.querySelector('a') ||
                        el.querySelector('[data-type=child] > button')
                )
                .filter(Boolean);
        },
        toggleDropdown(e) {
            this.isOpen = !this.isOpen;
        },
        closeDropdown() {
            this.isOpen = false;
        },
        hasOpenChildToggles() {
            const childToggles = this.focusableElements.filter(
                (el) =>
                    el.getAttribute('x-ref') === 'toggle' &&
                    el.getAttribute('aria-expanded') === 'true'
            );

            return childToggles.length ? true : false;
        },
        focusTrap(e) {
            if (this.hasOpenChildToggles() || !this.isOpen || !this.focusableElements.length) {
                return;
            }

            // List all focusable elements from this level, including current level toggle
            const elems = [this.$refs.toggle, ...this.focusableElements];
            // Find currently focused element
            const focusedElemKey = elems.findIndex((elem) => elem === e.target);
            if (focusedElemKey === -1) {
                return;
            }
            // Prevent default focus
            e.preventDefault();

            // Find out the next elem we are going to focus from the array and focus to it
            let nextElem;
            if (e.shiftKey) {
                nextElem = focusedElemKey - 1 < 0 ? elems.length - 1 : focusedElemKey - 1;
            } else {
                nextElem = (focusedElemKey + 1) % elems.length;
            }
            elems[nextElem].focus();
        },
    };
};
