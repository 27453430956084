import Quill from 'quill';

export default (props) => {
    return {
        value: '',
        wireModel: null,
        wireDefer: null,

        init() {
            if (this.wireModel) {
                this.value = this.$wire.get(this.wireModel);
            }
            let quill = new Quill(this.$refs.quill, { theme: 'snow' })

            quill.root.innerHTML = this.value

            quill.on('text-change', () => {
                this.value = quill.root.innerHTML;
            })

            this.$watch('value', (value) => {
                if (!this.wireModel) return;
                this.$wire.set(this.wireModel, value, this.wireDefer);
            })
        },

        ...props
    }
}