export default (props) => {
    return {
        id: null,
        wireModel: null,
        wireDefer: null,
        error: null,
        disabled: null,
        search: null,
        searchValue: null,
        placeholder: null,
        filter: null,
        searchValuePlaceholder: null,

        value: null, // Actual input value
        valueKey: null, // Selected result unique key
        currentIndex: -1, // The current index being hovered
        collapsed: true, // Is the dropdown collapsed
        isDirty: false, // Has the value been changed after last request
        loadingResults: false, // Are we loading results
        hasFocus: false,
        filteredElements: [],
        resultIndex: null,
        results: null,
        toggleContent: '',
        observer: null,
        options: null,
        valueSetFromLivewire: false,
        valueType: 'string',

        init() {
            if (this.wireModel) {
                this.value = this.getLivewireValue();
                Livewire.hook('element.updated', (el, component) => {
                    // If not this element, skip
                    if (el !== this.$root) {
                        return;
                    }

                    // Get the livewire value and compare to current value to skip updating if the same
                    // use loose comparison so this will be more accurate, otherwise type mismatch might happen
                    const value = this.getLivewireValue();
                    if (value == this.value) {
                        return;
                    }
                    this.updatedValueFromLivewire(value);
                })
            };

            // If options set via JS, build results after DOM loaded
            if (this.options) {
                this.$nextTick(() => {
                    this.buildResults();
                    this.findSelectedElementFromResults();
                    this.toggleContent = this.selectedElement ? this.getToggleElementContent(this.selectedElement) : '';
                });
            } else {
                this.buildResults();
                this.findSelectedElementFromResults();
                this.toggleContent = this.selectedElement ? this.getToggleElementContent(this.selectedElement) : '';
            }

            // Watch value changes and update the wire model value accordingly
            this.$watch('value', (value) => {
                if (this.wireModel && !this.valueSetFromLivewire) {
                    this.setLivewireValue(value, this.wireDefer);
                }
                this.toggleContent = this.selectedElement && value ? this.getToggleElementContent(this.selectedElement) : '';
                this.valueSetFromLivewire = false;
            })
        },

        getLivewireValue() {
            return this.$wire.get(this.wireModel);
        },

        setLivewireValue(value, defer) {
            this.$wire.set(this.wireModel, value, defer);
        },

        updatedValueFromLivewire(value) {
            // So we know we are updating the value from the livewire later in the hook
            this.valueSetFromLivewire = true;
            // Find selected result and set valuekey accordingly, use loose comparison so this will be more accurate, otherwise type mismatch might happen
            const selectedResult = [...this.results.values()].find((result) => result.value == value);
            if (selectedResult) {
                this.valueKey = selectedResult.elem.dataset.key;
            } else {
                this.valueKey = null;
            }
            this.currentIndex = this.results.get(this.valueKey) ? this.results.get(this.valueKey).index : -1;
            this.scrollSelectedToView();
            this.value = value;
        },

        getToggleElementContent(element) {
            return element.querySelector(`[x-ref=inputContent]`)
                ? element.querySelector(`[x-ref=inputContent]`).innerHTML
                : element.querySelector(`[x-ref=content]`).innerHTML;
        },

        resultsLoaded() {
            this.currentIndex = -1; // We have updated results so update current index
            this.buildResults();
            this.loadingResults = false;

            // Try to find the selected element and then set the index if it's found
            // Do this in the next tick so livewire has updated the DOM first
            this.$nextTick(() => {
                this.findSelectedElementFromResults();
            })
        },

        findSelectedElementFromResults() {
            let selectedResult;
            if (this.options) {
                selectedResult = [...this.results.values()].find((result) => result.value === this.value);

            } else {
                selectedResult = [...this.results.values()].find(result => result.elem.dataset.selected ? true : false);
            }
            if (!selectedResult) {
                return;
            }

            this.valueKey = selectedResult.elem.dataset.key;
            this.currentIndex = this.results.get(this.valueKey) ? this.results.get(this.valueKey).index : -1;
            this.scrollSelectedToView();
        },

        setValue() {
            if (!this.isElementSelectable(this.currentIndexElement)) {
                console.error(`Trying to set invalid or non-existing element or index as value`);
                return;
            }

            this.isDirty = true;
            this.value = this.results.get(this.resultIndex.get(this.currentIndex)).value;
            this.valueKey = this.resultIndex.get(this.currentIndex);
            this.searchValue = '';
            this.filterResults();
            this.close();
        },

        selectIndex(index) {
            if (!this.isIndexAllowed(index)) {
                console.error(`Trying to select invalid or missing index ${index}`);
            };

            this.currentIndex = index;
            this.setValue();
        },

        isIndexAllowed(index) {
            if (index === -1) return false;

            return this.resultIndex.has(index);
        },

        isElementSelectable(element) {
            return (element && !element.ariaDisabled && !element.attributes.disabled);
        },

        findElementByKey(key) {
            return this.results?.has(key) ? this.results.get(key).elem : null;
        },

        get currentIndexElement() {
            return this.isIndexAllowed(this.currentIndex) ? this.results.get(this.resultIndex.get(this.currentIndex)).elem : null;
        },

        get selectedElement() {
            return this.findElementByKey(this.valueKey);
        },

        get resultElements() {
            if (!this.$refs.resultList) {
                return [];
            }
            const resultElements = this.$refs.resultList.getElementsByTagName('li');
            return [...resultElements];
        },

        buildResults() {
            const resultsElements = this.filter && this.searchValue.length
                ? this.filteredElements
                : this.resultElements;

            const results = new Map();
            const resultIndex = new Map();

            resultsElements
                .filter(result => !result.ariaDisabled && result.getAttribute('disabled') !== 'disabled')
                .forEach((result, index) => {
                    results.set(result.dataset.key, {
                        value: result.dataset.value,
                        index: index,
                        textContent: result.dataset.filterContent || result.textContent,
                        hidden: false,
                        elem: result,
                    });

                    resultIndex.set(index, result.dataset.key);
                });

            this.results = results;
            this.resultIndex = resultIndex;
        },


        blur() {
            this.$refs.input.blur();
        },

        focus() {
            this.$refs.input.focus();
        },

        open() {
            this.collapsed = false;
            this.focus();
            if(this.currentIndex<=0) {
                // Hack, if livewire has changed the list, it'll force it to recheck it once opened
                this.findSelectedElementFromResults();
            }
        },

        close() {
            this.collapsed = true;
        },

        scrollSelectedToView() {
            const selectedResultElement = this.currentIndexElement;

            if (!selectedResultElement) return;

            const resultsPosition = this.$refs.resultContainer.getBoundingClientRect();
            const selectedPosition = selectedResultElement.getBoundingClientRect();

            if (selectedPosition.top < resultsPosition.top) {
                // Element is above viewable area
                this.$refs.resultContainer.scrollTop -= resultsPosition.top - selectedPosition.top;
            } else if (selectedPosition.bottom > resultsPosition.bottom) {
                // Element is below viewable area
                this.$refs.resultContainer.scrollTop += selectedPosition.bottom - resultsPosition.bottom;
            }
        },

        handleSearch() {
            if (this.filter) {
                this.filterResults();
                return;
            }

            // // Reset the value to actual value so Livewire does not try to
            // // set the search field value as value when we do the search request
            // this.setLivewireValue(this.value, true);

            if (this.searchValue.length < 2) {
                return;
            }

            this.loadingResults = true;

            this.$wire.call(
                this.search,
                this.searchValue
            ).then(() => {
                this.resultsLoaded();
            });
        },

        filterResults() {
            this.loadingResults = true;

            const search = new RegExp(`(${this.searchValue})\\w*`, 'gi');

            let index = 0;

            const resultIndex = new Map();
            for (const [key, result] of this.results) {
                const words = result.textContent.split(',');
                let match = false;

                for (const word of words) {
                    if (search.test(word)) {
                        match = true;
                        break;
                    }
                }

                if (match) {
                    resultIndex.set(index, key);
                    result.index = index;

                    index++;
                } else {
                    result.index = false;
                }
            }
            this.resultIndex = resultIndex;
            this.currentIndex = this.resultIndex.length === 0 ? -1 : 0;
            this.loadingResults = false;
        },

        handleResultClick(event, index) {
            event.preventDefault();
            this.selectIndex(index);
        },

        handleClick(event) {
            if (this.disabled) return;
            // Prevent default to prevent focus or blur from firing
            event.preventDefault();
            if (this.collapsed) {
                this.open();
            } else {
                this.close();
            }
        },

        handleFocus() {
            if (this.disabled) return;
            this.hasFocus = true;

            if (this.search) {
                this.open();
            }
        },

        handleBlur(event) {
            this.hasFocus = false;
            this.close();
        },

        move(direction) {
            const resultsCount = this.resultIndex.size;
            if (!resultsCount) return;
            const nextIndex = direction === 'up' ? this.currentIndex - 1 : this.currentIndex + 1;
            event.preventDefault();
            this.currentIndex = (nextIndex % resultsCount + resultsCount) % resultsCount;
        },

        handleKeyDown(event) {
            if (this.disabled) return;

            switch (event.key) {
                case 'Up': // IE/Edge
                case 'ArrowUp':
                case 'Down': // IE/Edge
                case 'ArrowDown': {
                    this.move(event.key === 'ArrowUp' || event.key === 'Up' ? 'up' : 'down');

                    if (this.collapsed) {
                        this.open();
                    } else {
                        this.scrollSelectedToView();
                    }
                    break;
                }

                case 'Tab': {
                    if ((this.search || this.filter) && !this.collapsed && this.isIndexAllowed(this.currentIndex)) this.setValue();

                    break;
                }

                case 'Enter': {
                    if (this.collapsed) {
                        this.open();
                    } else if (this.isIndexAllowed(this.currentIndex)) {
                        event.preventDefault();
                        this.setValue();
                    } else {
                        this.blur();
                    }
                    break;
                }

                case 'Spacebar': // IE
                case ' ': {
                    if (this.collapsed) {
                        event.preventDefault();
                        this.open();
                    //} else if (!this.search && this.isIndexAllowed(this.currentIndex)) {
                        //this.setValue(); < not sure why this was needed but it meant if you searched for "United Kingdom" it selects "DR Congo"
                    }
                    break;

                }

                case 'Esc': // IE/Edge
                case 'Escape': {
                    event.preventDefault();
                    this.close();
                    break;
                }

                default: {
                    // Filter out non-letter keycodes
                    if (event.code !== `Key${event.key.toUpperCase()}`) return;

                    if (this.collapsed && this.filter) {
                        this.open();
                    } else if (this.filter) {
                        return;
                    }
                    // Imitate select behaviour when you type a letter
                    if (!this.search && this.results.size) {
                        const foundResultElem = this.resultElements.find(elem => {
                            return elem.innerText.charAt(0).toLowerCase() === event.key.toLowerCase();
                        });

                        if (foundResultElem) {
                            this.currentIndex = this.results.get(foundResultElem.dataset.key).index;
                        }
                    }
                }
            }
        },

        ...props,
    }
}
