export default (props) => {
    return {
        months: [
            {
                monthIndex: 0,
                number: 1,
                full: 'January',
                short: 'Jan',
            },
            {
                monthIndex: 1,
                number: 2,
                full: 'February',
                short: 'Feb',
            },
            {
                monthIndex: 2,
                number: 3,
                full: 'March',
                short: 'Mar',
            },
            {
                monthIndex: 3,
                number: 4,
                full: 'April',
                short: 'Apr',
            },
            {
                monthIndex: 4,
                number: 5,
                full: 'May',
                short: 'May',
            },
            {
                monthIndex: 5,
                number: 6,
                full: 'June',
                short: 'Jun',
            },
            {
                monthIndex: 6,
                number: 7,
                full: 'July',
                short: 'Jul',
            },
            {
                monthIndex: 7,
                number: 8,
                full: 'August',
                short: 'Aug',
            },
            {
                monthIndex: 8,
                number: 9,
                full: 'September',
                short: 'Sep',
            },
            {
                monthIndex: 9,
                number: 10,
                full: 'October',
                short: 'Oct',
            },
            {
                monthIndex: 10,
                number: 11,
                full: 'November',
                short: 'Nov',
            },
            {
                monthIndex: 11,
                number: 12,
                full: 'December',
                short: 'Dec',
            },
        ],

        days: [
            {
                dayIndex: 0,
                full: 'Sunday',
                short: 'Sun',
            },
            {
                dayIndex: 1,
                full: 'Monday',
                short: 'Mon',
            },
            {
                dayIndex: 2,
                full: 'Tuesday',
                short: 'Tue',
            },
            {
                dayIndex: 3,
                full: 'Wednesday',
                short: 'Wed',
            },
            {
                dayIndex: 4,
                full: 'Thursday',
                short: 'Thu',
            },
            {
                dayIndex: 5,
                full: 'Friday',
                short: 'Fri',
            },
            {
                dayIndex: 6,
                full: 'Saturday',
                short: 'Sat',
            },
        ],

        showDatepicker: false,
        day: null,
        month: null,
        year: null,
        dateInput: '',
        noOfDays: [],
        blankdays: [],
        sortedDays: [],
        value: {}, // Current form field value
        datepickerValue: {}, // Current datepicker value
        errorText: '',

        init() {
            if (this.value?.day && this.value?.month && this.value?.year) {
                this.handleValueUpdate(this.value);
            }
            this.setDatePickerValue(this.convertValueToDate(this.value));
            this.setInputValue(this.value);
            this.getNoOfDays();
            this.sortedDays = [...this.days];
            this.sortedDays.sort((firstEl, secondEl) => {
                if (firstEl.dayIndex === 0 || secondEl.dayIndex === 0) {
                    return -1;
                }
                return firstEl.dayIndex > secondEl.dayIndex;
            });

            this.$watch('dateInput', (value) => {
                const dateArray = value.split('.');

                if (dateArray.length === 3) {
                    this.year = dateArray[2];
                    this.month = dateArray[1];
                    this.day = dateArray[0];

                    this.errorText = '';
                } else {
                    this.errorText = 'Please enter a valid date, e.g. 01.01.2020';
                }
            });
            this.$watch('day', (value) => this.updateDay(value));
            this.$watch('month', (value) => this.updateMonth(value));
            this.$watch('year', (value) => this.updateYear(value));
            this.$watch('value', (value) => this.handleValueUpdate(value));
        },

        handleValueUpdate(value) {
            if (this.day === value.day && this.month === value.month && this.year === value.year) {
                return;
            }
            this.day = value.day;
            this.month = value.month;
            this.year = value.year;
        },

        convertValueToDate(value) {
            const today = new Date();
            const defaults = {
                day: today.getDate(),
                month: today.getMonth() + 1,
                year: today.getFullYear(),
            };
            return new Date(
                value?.year || defaults.year,
                (value?.month || defaults.month) - 1,
                value?.day || defaults.day
            );
        },

        isSelectedDate(day) {
            return day === this.day;
        },

        isToday(day) {
            const today = new Date();
            return today.getDate() === day;
        },

        getMonthName(month, type) {
            const monthObject = this.months.find(
                (item) => parseInt(item.monthIndex, 10) === parseInt(month, 10)
            );
            if (!monthObject) {
                console.error(`Month ${month} does not exist!`);
                return '';
            }
            return monthObject[type];
        },

        getDayName(day, type) {
            const dayObject = this.days.find(
                (item) => parseInt(item.dayIndex, 10) === parseInt(day, 10)
            );
            if (!dayObject) {
                console.error(`Day ${day} does not exist!`);
                return '';
            }
            return dayObject[type];
        },

        updateValue() {
            let newValue = {
                day: this.day,
                month: this.month,
                year: this.year,
            };
            const newValueDate = this.convertValueToDate(newValue);
            if (this.maxValue) {
                const maxDate = this.convertValueToDate(this.maxValue);
                newValue = newValueDate > maxDate ? this.maxValue : newValue;
            }
            if (this.minValue) {
                const minDate = this.convertValueToDate(this.minValue);
                newValue = newValueDate < minDate ? this.minValue : newValue;
            }
            this.value = newValue;
        },

        parseDateInputValue(value, min, max, defaultValue) {
            if (!value) {
                return null;
            }
            let parsedValue = parseInt(value, 10);
            if (isNaN(parsedValue)) {
                parsedValue = defaultValue;
            }

            if (parsedValue > max) {
                parsedValue = max;
            }
            if (parsedValue < min) {
                parsedValue = min;
            }

            return parsedValue;
        },

        updateDay(value) {
            this.day = this.parseDateInputValue(
                value,
                1,
                this.getDaysInMonth(this.month, this.year),
                1
            );
            this.afterInput();
        },

        updateMonth(value) {
            this.month = this.parseDateInputValue(value, 1, 12, 1);
            this.afterInput();
        },

        updateYear(value) {
            this.year = this.parseDateInputValue(value, 1900, 2099, null);
            this.afterInput();
        },

        setInputValue(value) {
            this.dateInput = this.convertValueToDate(value).toLocaleDateString('fi-FI', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
        },

        afterInput() {
            this.updateValue();
            this.setDatePickerValue(this.convertValueToDate(this.value));
            this.setInputValue(this.value);
            this.getNoOfDays();
        },

        setDatePickerValue(date) {
            this.datepickerValue = {
                day: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear(),
            };
        },

        setValueByDay(day) {
            this.updateDay(day);
            this.updateMonth(this.datepickerValue.month + 1);
            this.updateYear(this.datepickerValue.year);
            this.afterInput();
            this.handleBlur();
        },

        getNoOfDays() {
            let daysInMonth = this.getDaysInMonth(
                this.datepickerValue.month + 1,
                this.datepickerValue.year
            );
            // find where to start calendar day of week
            let dayOfWeek = new Date(
                this.datepickerValue.year,
                this.datepickerValue.month,
                0
            ).getDay();

            let blankdaysArray = [];
            for (var i = 1; i <= dayOfWeek; i++) {
                blankdaysArray.push(i);
            }
            let daysArray = [];
            for (var i = 1; i <= daysInMonth; i++) {
                daysArray.push(i);
            }
            this.blankdays = blankdaysArray;
            this.noOfDays = daysArray;
        },

        getDaysInMonth(month, year) {
            return new Date(year, month, 0).getDate();
        },

        handleFocus() {
            // this.showDatepicker = true;
        },

        handleBlur() {
            this.$nextTick(() => {
                this.showDatepicker = false;
            });
        },

        handlePrevMonth() {
            if (this.month === 1) {
                this.month = this.month = 12;
                this.year = this.year - 1;
            } else {
                this.month = this.month - 1;
            }
            //this.getNoOfDays();
        },

        handleNextMonth() {
            if (this.month === 12) {
                this.month = 1;
                this.year = this.year + 1;
            } else {
                this.month = this.month + 1;
            }
            //this.getNoOfDays();
        },

        goToToday() {
            const today = new Date();
            this.updateDay(today.getDate());
            this.updateMonth(today.getMonth() + 1);
            this.updateYear(today.getFullYear());
        },
        ...props,
    };
};
