// import glider from 'glider-js';
// Commented out because import is not made so it would actually work
// import testimonialCarousel from './site/glider/testimonial-carousel';

// export { glider };

import {
    initUiComponents
} from './ui-components';

// Initializes all UI components and starts AlpineJS
initUiComponents();

// Minna has marked every link in this Galaxy as "Open in new tab" so we
// have to remove it for internal links because she's also too lazy to go
// back and fix her mistakes in the content itself ¯\_(ツ)_/¯
document.addEventListener('DOMContentLoaded', () => {
    // Get the current domain
    const currentDomain = window.location.origin;

    // Function to process a single link
    function processLink(link) {
        const href = link.getAttribute('href');

        // Skip if no href
        if (!href) {
            return;
        }

        // Check if it's an internal link
        const isInternal = href.startsWith('/') || href.startsWith(currentDomain) || !href.includes('://') || href.includes('api.shipit.ax');

        if (isInternal) {
            // Remove target="_blank" from internal links
            link.removeAttribute('target');

            // Also remove rel="noopener" if it was added automatically
            if (link.getAttribute('rel') === 'noopener') {
                link.removeAttribute('rel');
            }
        }
    }

    // Process all existing links
    for (const link of document.querySelectorAll('a')) {
        processLink(link);
    }

    // Set up a MutationObserver to handle dynamically added links
    const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
            for (const node of mutation.addedNodes) {
                if (node.nodeType === 1) { // ELEMENT_NODE
                    if (node.tagName === 'A') {
                        processLink(node);
                    }

                    for (const link of node.querySelectorAll('a')) {
                        processLink(link);
                    }
                }
            }
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
});
