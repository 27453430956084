export default function initDisableBodyScroll() {
    window.addEventListener('disable-body-scroll', (event) => {
        let bodyClassList = document.body.classList;

        if (event.detail.value === true) {
            bodyClassList.add('overflow-y-hidden');
        } else {
            bodyClassList.remove('overflow-y-hidden');
        }
    });
}