import AutocompleteCore from '@trevoreyre/autocomplete';
import { focusNextElement } from '../inc/helpers';

export default (props) => {
    return {
        results: [], // Search results
        options: [], // Select options
        defaultChoices: [], // Choicesshown when 0 results have been found and search field is empty)
        selectedIndex: null, // Currently selected index
        selectedResult: null, // Currently selected result
        value: null, // Current form field value
        resultListExpanded: false, // Result list expanded state
        showNoResults: false,
        loadingResults: false, // Loading results
        autoSelect: true, // Automatically select first value
        collapsed: true, // Collapsed state
        showAllOptionsByDefault: false,
        core: null,
        resultListId: null,
        search: null, // Search input field
        lastEvent: null, // Last event that was triggered
        focusNextElement: focusNextElement,
        noResults: true,
        isfirstEvent: true,
        hasFocus: false,
        wireModel: '',
        wireDefer: true,
        allowCustomValue: false, // Allow any value to be entered which will be the search input value
        optionsUpdated: false, // Options have been updated

        init() {

            this.setInitialValue();
            this.resultListId = this.$id('autocomplete-result-list-');
            this.$watch('value', (value) => this.handleValueChange());

            this.handleOptionsChange();
            this.$watch('options', () => this.handleOptionsChange());

            this.core = new AutocompleteCore({
                autoSelect: this.autoSelect,
                search: (input) => this.search(input),
                setValue: (result) => this.setValue(result),
                setAttribute: (attribute, value) => this.setAttribute(attribute, value),
                onUpdate: (results, selectedIndex) => this.handleUpdate(results, selectedIndex),
                onShow: () => this.handleShow(),
                onHide: () => this.handleHide(),
                onLoading: () => this.handleLoading(),
                onLoaded: () => this.handleLoaded(),
                onSubmit: (selectedResult) => this.handleSubmit(selectedResult),
            });
        },

        handleOptionsChange() {
            if (this.optionsUpdated) {
                this.optionsUpdated = false;
                return;
            }
            this.options = this.options.map(option => {
                option.keywords = option.keywords.map(keyword => (typeof keyword === 'number') ? keyword.toString() : keyword);
                return option;
            });
            this.optionsUpdated = true;
        },

        // Handle when livewire changes value
        handleValueChange(value) {
            if (this.value === value) {
                return;
            }
            this.$nextTick(() => {
                this.setInitialValue();
            });
        },

        setInitialValue() {
            // Find the initial result
            const result = this.options.find((option) => option.value === this.value);
            if (result) {
                this.selectedResult = result;
            }

            if (this.allowCustomValue) {
                this.$refs.search.value = this.value;
            }
        },

        handleUpdate(results, index) {
            this.results = results;
            this.selectedIndex = index;

            this.scrollIntoView();
        },

        setIndex() {
            this.$nextTick(() => {
                const resultIndex = this.results.findIndex(
                    (result) => this.selectedResult?.value === result.value
                );
                if (resultIndex === -1) {
                    return;
                }
                this.selectedIndex = resultIndex;
                this.core.selectedIndex = resultIndex;
            });
        },

        setAttribute(attribute, value) {
            this.$refs.search.setAttribute(attribute, value);
        },

        setValue(result) {
            if (!this.allowCustomValue && this.collapsed) {
                return;
            }

            if (this.allowCustomValue) {
                this.value = result?.value || this.$refs.search.value;
                this.$refs.search.value = this.value;
            } else {
                this.value = result?.value ?? '';
            }

            if (this.wireModel.length) {
                this.$wire.set(this.wireModel, this.value, this.wireDefer ? true : false);
            }
            this.selectedResult = result;
            this.collapsed = true;
            this.resultListExpanded = false;
        },

        handleSubmit(selectedResult) {
            if (selectedResult || this.allowCustomValue) {
                this.setValue(selectedResult);
                if (this.lastEvent?.type === 'keydown') {
                    this.focusNextElement(this.$el);
                }
            }
        },

        handleShow() {
            this.resultListExpanded = true;
            this.collapsed = false;
            this.setIndex();
            this.scrollIntoView();
            this.loadingResults = false;
        },

        handleHide() {
            this.resultListExpanded = false;
            this.loadingResults = false;
        },

        handleLoading() {
            this.loadingResults = true;
        },

        handleLoaded() { },

        handleKeyDown(event) {
            this.lastEvent = event;
            // Prevent selecting the first result value when user is just tabbing over the selection quickly
            if (this.isfirstEvent && event.key === 'Tab') {
                return;
            }
            this.isfirstEvent = false;

            if (event.key === 'Enter') {
                event.preventDefault();
            }

            this.core.handleKeyDown(event);
        },

        handleFocus(event) {
            // Prevent doubleclicks etc
            if (this.hasFocus) {
                return;
            }
            this.hasFocus = true;
            this.lastEvent = event;
            this.$nextTick(() => {
                this.core.handleFocus(event);
            });
        },

        handleClick(event) {
            // Prevent doubleclicks
            if (this.hasFocus) {
                return;
            }
            this.$refs.search.focus();
        },

        handleBlur(event) {
            // Empty the search field if no results
            if (!this.results.length && !this.allowCustomValue) {
                this.$refs.search.value = '';
            }
            this.lastEvent = event;
            this.$nextTick(() => {
                this.core.handleBlur(event);
                this.collapsed = true;
                this.hasFocus = false;
            });
            this.isfirstEvent = true;
        },

        handleInput(event) {
            this.lastEvent = event;

            if (this.allowCustomValue) {
                this.value = this.$refs.search.value;
            }
            this.core.handleInput(event);
        },

        scrollIntoView() {
            this.$nextTick(() => {
                this.core.checkSelectedResultVisible(this.$refs.resultList);
            });
        },

        search(input) {
            let results;
            if (input.length < 1) {
                results = this.showAllOptionsByDefault ? this.options : [];
            } else {
                results = this.options.filter((option) => {
                    return (
                        option.keywords.filter((item) => item ?
                            item.toLowerCase().startsWith(input.toLowerCase()) : false
                        ).length > 0
                    );
                });
            }

            this.showNoResults = results.length === 0;
            return this.handleResults(results);
        },

        handleResults(results) {
            return results.map((result, index) => {
                return {
                    ...result,
                    id: `${this.resultListId}-item-${index}`,
                    index: index,
                };
            });
        },
        ...props,
    };
};
