export default (initialToasts) => {
    return {
        toasts: [],
        init() {
            initialToasts.forEach((toast) => {
                this.addToast(toast);
            });
        },
        removeToast(toast) {
            this.toasts.splice(this.toasts.indexOf(toast), 1);
        },
        addToast(toast) {
            this.toasts.push(toast);
            setTimeout(() => {
                this.removeToast(toast);
            }, 5000);
        },
    };
};
