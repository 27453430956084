export default function initStepNavigation() {
    // Save shipping tool step state to browser history so back button will work correctly
    window.addEventListener('shipping-tool-step-navigation', (event) => {
        // We don't want duplicates of the state in case user reloads etc
        if (window.history.state && window.history.state?.stepKey === event.detail.key) {
            return;
        }
        const url = new URL(window.location);
        url.searchParams.set('step', event.detail.key);
        window.history.pushState(
            { stepKey: event.detail.key, prevStepKey: window.history.state.stepKey ?? '' },
            '',
            url
        );
    });

    window.onpopstate = function (event) {
        if (event?.state?.stepKey) {
            Livewire.emitTo(
                'shipping-tool.basic',
                'loadFromHistoryState',
                event.state.stepKey,
                event.state.prevStepKey
            );
        }
    };
}