import ApexCharts from 'apexcharts';

export default (props) => {
    return {
        data: {},
        dataMethod: null,
        loading: true,
        init() {
            if (this.dataMethod) {
                this.$wire.call(this.dataMethod).then(data => {
                    this.initChart(data);
                })
            } else {
                this.initChart(this.data);
            }

        },
        initChart(data) {
            const chart = new ApexCharts(this.$refs.chartContainer, data);
            chart.render();
            this.loading = false;
        },

        ...props
    }
}